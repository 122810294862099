<template lang="pug">
.brand-wrapper.om-wizard-similar-templates
  .container-fluid.position-relative
    .row
      .col-12.d-flex.justify-content-start
        div
          om-button.mr-4(primary ghost @click="$router.go(-1)" icon="angle-left-b") {{ $t('back') }}
        wizard-title.text-left.mb-3.pb-3(v-if="theme") {{ theme.name }}
    .row(v-if="theme" :ref="paletteRef")
      .col-12.col-sm-6.col-md-4.col-xl-3(
        v-for="template in theme.templates"
        style="margin-bottom: 1.5rem"
      )
        template-frame.cursor-pointer(
          @observable="addObservable($event.$el)"
          allowSsr
          @inited="updateDimensions"
          :dimensions="boxDimensions"
          :template="template"
          :themeKit="theme.themeKit"
          @contentLoaded="onContentLoaded"
          @click.native="showModal(template)"
        )
          template(#fallback)
            template-box.cursor-pointer(
              uniqueColors
              :key="template._id"
              :template="template"
              :color="color"
              @click.native="$modal.show('template-preview', { templateId: template._id, color, themeKit: themeName })"
            )
  portal(to="root")
    template-preview
  portal(to="root")
    NewCampaign
</template>

<script>
  import TemplateBox from '@/components/Template/Themes/TemplateBox.vue';
  import TemplatePreview from '@/components/Modals/TemplatePreview';
  import NewCampaign from '@/components/Modals/NewCampaign';
  import TemplateFrame from '@/components/Template/TemplateFrame.vue';
  import WizardTitle from '@/components/Wizard/Title.vue';
  import paletteMixin from '@/mixins/palette';
  import previewParentMixin from '@/mixins/previewParent';
  import ssrMixin from '@/mixins/ssr';
  import observableCollectionMixin from '@/mixins/observableCollection';
  import GET_CUSTOM_THEMES from '@/graphql/GetCustomThemes.gql';

  export default {
    components: { TemplateBox, WizardTitle, TemplatePreview, NewCampaign, TemplateFrame },
    mixins: [paletteMixin, previewParentMixin, ssrMixin, observableCollectionMixin],

    apollo: {
      themes: {
        query: GET_CUSTOM_THEMES,
        variables() {
          return { id: this.id };
        },
      },
    },

    computed: {
      theme() {
        return this.themes?.custom?.[0];
      },
      themeName() {
        return this.theme?.name || this.template.theme;
      },
      color() {
        return this.theme?.themeKit?.colors.mainColor;
      },
      id() {
        const id = this.$route.params.id || this.$route.query.id;
        return id;
      },
    },

    methods: {
      showModal(template) {
        this.$modal.show('template-preview', {
          templateId: template._id,
          themeKit: this.theme?.themeKit,
          heapTracking: {
            name: 'your-style-preview',
            data: {
              templateName: template.name,
              themeId: this.theme?._id,
              theme: this.theme?.themeKit?.name,
            },
          },
        });
      },
    },
  };
</script>

<style lang="sass">
  .om-wizard-similar-templates
    padding-bottom: 3.5rem

    .brand-themes-template-box
      width: 100%
      padding: 0
</style>
